<template>
	<div>
		<Dialog
			v-show="!extentionDialog"
			:dialog-width="600"
			:temporary="!extentionDialog"
			:dialog.sync="drawerVal"
			@close="$emit('close')"
		>
			<template v-slot:title>
				<v-flex class="py-2 d-flex justify-space-between">
					<div class="">{{ titleStatus }} Reminder</div>
					<div class="bg-white" style="width: 200px; border-radius: 50px" v-if="related == null">
						<AutoCompleteInput
							style="border-radius: 50px !important"
							hideTopMargin
							dense
							id="related-to"
							hide-details
							placeholder="Related To"
							:items="relatedList"
							:loading="pageLoading"
							item-text="label"
							item-value="value"
							v-model="reminder.related_to"
							@change="
								(reminder.related_id = 0),
									(reminder.type = 1),
									reminder.related_to == 'meeting'
										? (reminder.callType = 'other')
										: (reminder.callType = 'other')
							"
							:disabled="reminder.id ? true : false"
						>
						</AutoCompleteInput>
					</div>
				</v-flex>
			</template>
			<template v-slot:body>
				<div class="">
					<v-form
						ref="reminderForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="update_or_create"
					>
						<div class="drawer-content pt-5 px-5">
							<v-row class="mb-3" v-if="related == null">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500 required text-capitalize">
										<template v-if="reminder.related_to == 'lead'">Company Name</template>
										<template v-else-if="reminder.related_to == 'task'">Task </template>
										<template v-else-if="reminder.related_to == 'customer'">Customer </template>
										<template v-else-if="reminder.related_to == 'meeting'">Meeting </template>
									</label>
								</v-col>
								<v-col md="9">
									<div>
										<div v-if="reminder.related == null" class="d-flex">
											<v-autocomplete
												:items="getDataList"
												item-text="text"
												item-value="value"
												:placeholder="getPlaceholder(reminder.related_to)"
												depressed
												hide-details
												outlined
												v-model="reminder.related_id"
												:rules="[vrules.required(reminder.related_id, getRelatedErrorMsg)]"
												:class="{
													required: !reminder.related_id,
												}"
											>
												<template v-slot:selection="data">
													<span class="text-uppercase">
														{{ data.item.text }}
														{{ data.item.lead_barcode ? `(${data.item.lead_barcode})` : null }}
													</span>
												</template>
												<template v-slot:item="data">
													<template>
														<v-list-item-content>
															<v-list-item-title
																>{{ data.item.text
																}}{{
																	data.item.lead_barcode ? `(${data.item.lead_barcode})` : null
																}}</v-list-item-title
															>
														</v-list-item-content>
													</template>
												</template>
											</v-autocomplete>
										</div>
									</div>
								</v-col>
							</v-row>

							<v-row class="mb-3">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500">Title</label>
								</v-col>
								<v-col md="9">
									<v-text-field
										depressed
										hide-details
										outlined
										v-model="reminder.title"
										:disabled="pageLoading"
										:loading="pageLoading"
										placeholder="Title"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row class="mb-3" v-if="!reminder?.id">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500">Reminder Type </label>
								</v-col>
								<v-col md="9">
									<AutoCompleteInput
										dense
										id="reminder-type"
										hide-details
										placeholder="Reminder Type"
										:items="reminder.related_to == 'meeting' ? reminder_type_items2 : reminder_type_items"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="reminder.type"
										item-value="value"
										v-on:change="get_reminder_values()"
									>
									</AutoCompleteInput>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500 required">Start Date</label>
								</v-col>
								<v-col md="9">
									<DateTimePicker
										dense
										id="reminder-start-date"
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="reminder.start_date"
										:placeholder="reminder.type != 1 ? 'Select Start Date...' : 'Select Start Date...'"
										v-on:change="get_reminder_values()"
										:rules="[
											vrules.required(reminder.start_date, reminder.type != 1 ? 'Start Date' : 'Start Date'),
										]"
										:class="{ required: !reminder.start_date }"
										hideTopMargin
									></DateTimePicker>
								</v-col>
							</v-row>
							<v-row class="mb-3" v-if="!reminder.id && reminder.type != 1">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500">Frequency </label>
								</v-col>
								<v-col md="9">
									<v-text-field
										type="Number"
										v-mask="'####'"
										depressed
										hide-details
										outlined
										placeholder="Enter Frequency..."
										v-model="reminder.frequency"
										:disabled="pageLoading || reminder?.id > 0"
										:loading="pageLoading"
										v-on:keyup="get_reminder_values()"
										@wheel="$event.target.blur()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row class="mb-3" v-if="!reminder.id && reminder.type != 1">
								<v-col md="3" class="my-auto text-end">
									<label class="fw-500">End Date</label>
								</v-col>
								<v-col md="9">
									<DateTimePicker
										dense
										readonly
										id="reminder-start-date"
										hide-details
										:disabled="true"
										:loading="pageLoading"
										v-model="reminder.end_date"
										:placeholder="'Select End Date...'"
										v-on:change="get_reminder_values()"
										:rules="[
											vrules.required(reminder.end_date, reminder.type != 1 ? 'End Date' : 'End Date'),
										]"
										:class="{ required: !reminder.end_date }"
										hideTopMargin
									></DateTimePicker>
								</v-col>
							</v-row>
							<v-row class="mb-3" v-if="reminder?.id > 0">
								<v-col md="3" class="my-auto text-end"></v-col>

								<v-col md="9">
									<v-radio-group v-model="reminder.extention" row hide-details class="mt-0">
										<div style="margin-right: 50px">
											<v-radio class="" value="single">
												<template #label>
													<v-chip color="primary" label outlined small class="ml-2">
														<span style="font-size: 13px"> Extend this reminder </span>
													</v-chip>
												</template>
											</v-radio>
										</div>
										<div v-if="reminder.type != 1" class="pt-2">
											<v-radio class="" value="multiple">
												<template #label>
													<v-chip color="red" label outlined small class="ml-2">
														<span style="font-size: 13px"> Extend all upcoming reminder </span>
													</v-chip>
												</template>
											</v-radio>
										</div>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row class="mb-0" v-if="reminder.related_to != 'meeting'">
								<v-col md="3" class="my-auto text-end"></v-col>
								<v-col md="9">
									<RadioInputCustom
										v-model="reminder.callType"
										@change="updateCallType"
										row
										color="blue darken-4"
										class="mt-0"
										:items="[
											{
												label: 'Call',
												value: 'call',
												img: '/media/svg/icons/Devices/Phone.svg',
											},
											{
												label: 'Email',
												value: 'email',
												img: '/media/form-svg/email.svg',
											},
											{
												label: 'Other',
												value: 'other',
												img: '/media/client-logos/other.png',
											},
										]"
									/>
								</v-col>
							</v-row>

							<template v-if="reminder.callType == 'other' && false">
								<div class="mb-3">
									<div class="d-flex pt-1">
										<label class="fw-500">Customer</label>
									</div>
									<AutoCompleteInput
										dense
										id="customer"
										hide-details
										placeholder="Customer"
										:items="customerList"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="reminder.customer"
										item-value="value"
									>
									</AutoCompleteInput>
								</div>
								<div class="mb-3">
									<div class="d-flex pt-1">
										<label class="fw-500">Project</label>
									</div>
									<AutoCompleteInput
										dense
										id="project"
										hide-details
										placeholder="Project"
										:items="relatedProjectList"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="reminder.project"
										item-value="value"
									>
									</AutoCompleteInput>
								</div>
							</template>

							<v-row class="mb-3" v-if="false">
								<v-col md="3" class="text-end">
									<label class="fw-500">Description</label>
								</v-col>
								<v-col md="9">
									<ckeditor
										:editor="editor"
										v-model="reminder.description"
										:config="editorConfig"
									></ckeditor>
								</v-col>
							</v-row>
						</div>
					</v-form>
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile class="mr-2" v-on:click="closeDrawer()">Cancel</v-btn>
				<v-btn
					tile
					depressed
					color="white--text"
					class="blue darken-4"
					v-on:click="update_or_create"
					:loading="pageLoading"
					:disabled="pageLoading"
					>Save</v-btn
				>
			</template>
		</Dialog>
		<Dialog :dialog="extentionDialog" :dialog-width="710" persistent content-class="testdata">
			<template v-slot:title>Extended Reminder <v-spacer></v-spacer> </template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<v-icon color="green" size="55">mdi-check</v-icon>
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0" style="font-size: 18px">
							<template v-if="reminder && reminder.extention && reminder.extention == 'single'">
								You have successfully updated only this reminder
							</template>
							<template v-if="reminder && reminder.extention && reminder.extention == 'multiple'">
								You have successfully updated all reminders
							</template>
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn tile depressed color="white--text" class="blue darken-4" v-on:click="confirmDialog">
					Ok
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { POST, PUT } from "@/core/services/store/request.module";
import { filter, map } from "lodash";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import MainMixin from "@/core/mixins/main.mixin.js";
/* import DatePicker from "@/view/components/DatePicker"; */
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import TextInput from "@/view/components/TextInput";
import RadioInputCustom from "@/view/components/RadioInputCustom";
import MomentJS from "moment-timezone";
import DateTimePicker from "@/view/components/DateTimePicker";
import { SET_RELATED_DB } from "@/core/services/store/config.module.js";
import { CLEAR_REMINDER, GET_REMINDER } from "@/core/services/store/reminder.module.js";
export default {
	mixins: [MainMixin],
	name: "CreateReminderV2",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		reminderId: {
			type: [Number, String],
			default: 0,
		},
		related: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		titleStatus: {
			type: String,
		},
	},
	watch: {
		drawer: {
			immediate: true,
			handler(param) {
				this.drawerVal = param;
			},
		},
	},
	components: {
		/* DatePicker, */
		Dialog,
		AutoCompleteInput,
		DateTimePicker,
		// TextInput,
		RadioInputCustom,
	},
	data() {
		return {
			defaultReminderType: null,
			projectList: [],
			allProjects: [],
			formValid: true,
			extentionDialog: false,
			drawerVal: false,
			companyName: null,
			today: new Date(),
			relatedList: [
				{
					label: "Lead",
					value: "lead",
				},
				{
					label: "Customer",
					value: "customer",
				},
				{
					label: "Task",
					value: "task",
				},
				{
					label: "Meeting",
					value: "meeting",
				},
			],
			reminder_type_items: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 2, text: "Daily Reminder" },
				{ value: 3, text: "Weekly Reminder" },
				{ value: 4, text: "Monthly Reminder" },
				{ value: 5, text: "Quarterly Reminder" },
				{ value: 6, text: "Half Yearly Reminder" },
			],
			reminder_type_items2: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 3, text: "Weekly Reminder" },
			],
			pageLoading: false,
			dataLists: [],
			editor: ClassicEditor,
			editorData: "<p>Description</p>",
			editorConfig: {
				// The configuration of the editor.
			},

			date_dialog: false,
			from_date_dialog: false,
			reminder: {
				id: 0,
				extention: "single",
				title: null,
				start_date: this.formatDateTimeRaw(new Date()),
				end_date: null,
				callType: "other",
				attendees: [],
				type: 1,
				frequency: 1,
				related_to: "customer",
				related_id: 0,
				description: null,
				customer: 0,
				project: 0,
			},
			emit_data: {},
		};
	},
	methods: {
		confirmDialog() {
			this.$emit("close", true);
			this.$emit("success", this.reminder);
			this.extentionDialog = false;
			this.resetForm();
		},
		getPlaceholder(type) {
			if (type == "lead") {
				return "Select Lead";
			} else if (type == "customer") {
				return "Select Customer";
			} else if (type == "task") {
				return "Select Task";
			} else if (type == "meeting") {
				return "Select Meeting";
			}
		},
		updateCallType() {
			if (this.related == "customer") {
				this.reminder.customer = this.relatedId;
			}
		},
		get_reminder_values() {
			// if(!this.reminder.frequency){
			// 	this.reminder.frequency=1;
			// }

			let reminderParseDate = this.reminder.start_date;
			if (this.reminder.start_date) {
				for (var i = 0; i < this.reminder.frequency; i++) {
					if (this.reminder.type == 6) {
						/* Half Yearly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(6, "months");
					}

					if (this.reminder.type == 5) {
						/* Quarterly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(3, "months");
					}

					if (this.reminder.type == 4) {
						/* Monthly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(1, "months");
					}

					if (this.reminder.type == 3) {
						/* Weekly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(7, "days");
					}

					if (this.reminder.type == 2) {
						/* Daily */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(1, "days");
					}

					if (this.reminder.type == 1) {
						/* One Time */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(7, "days");
					}
				}
				this.reminder.end_date = reminderParseDate.format("YYYY-MM-DD HH:mm");
				return this.reminder.end_date;
			}
		},
		remove(item) {
			const index = this.reminder.attendees.indexOf(item.id);
			this.reminder.attendees.splice(index, 1);
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["reminderForm"]);
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (this.reminder && this.reminder.id) {
				this.updateReminderData();
			} else {
				this.saveReminderData();
			}
		},
		updateReminderData() {
			if (this.reminder.type == 1) {
				this.reminder.end_date = null;
			}
			this.pageLoading = true;
			this.$store
				.dispatch(PUT, {
					url: `reminder/${this.reminder.id}`,
					data: this.reminder,
				})
				.then((data) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder updated successfully." },
					]);

					if (this.reminder.type != 1) {
						// this.emit_data = data;
						// this.resetForm();
						this.extentionDialog = true;
					} else {
						this.$nextTick(() => {
							this.resetForm();
							this.$emit("success", data);
							this.$emit("close", true);
						});
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		saveReminderData() {
			this.pageLoading = true;
			if (this.reminder.type == 1) {
				this.reminder.end_date = null;
			}
			this.$store
				.dispatch(POST, { url: `reminder`, data: this.reminder })
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder added successfully." },
					]);
					this.resetForm();
					this.getReminder();
					this.$emit("success", data);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		resetForm() {
			this.reminder.description = "";
			this.$refs.reminderForm.reset();
		},
		closeDrawer() {
			this.resetForm();
			this.$emit("close", true);
		},
		getReminder() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET_REMINDER, this.reminderId)
				.then((data) => {
					const attendees = map(data.attendies, (row) => row.id);
					this.reminder = {
						id: data.id,
						barcode: data.barcode,
						extention: "single",
						title: data.title,
						start_date: data.start_date,
						end_date: data.end_date_date,
						callType: data.callType,
						attendees: attendees,
						type: data.type,
						frequency: data.frequency,
						related_to: data.related_to,
						related_id: data.related_id,
						description: data.description,
						customer: data.customer,
						project: data.project,
						status: data.status,
					};

					if (this.reminder.related_to == "meeting") this.reminder.callType = "other";

					this.defaultReminderType = this.reminder.related_to;
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			this.$store.dispatch(SET_RELATED_DB);
			this.reminder.related_to = this.related ? this.related : "lead";
			this.reminder.related_id = this.relatedId ? this.relatedId : 0;

			if (this.reminderId) {
				this.getReminder();
			}
		},
	},
	computed: {
		...mapGetters([
			"errors",
			"currentUser",
			"getUsers",
			"relatedDB",
			"gReminder",
			"userType",
			"isMaster",
		]),
		getTitle() {
			if (this.reminderId) {
				return "Update Reminder";
			}
			return "Create Reminder";
		},
		getDataList() {
			let data = [];
			switch (this.reminder.related_to) {
				case "lead":
					data = this.relatedDB("lead", []);
					break;
				case "customer":
					data = this.relatedDB("all_customers", []);
					console.log({ data });
					break;
				case "meeting":
					data = this.relatedDB("meeting", []);

					data = data.filter((row) => {
						if (row && row.value) {
							if (row.meeting_status != 1 && row.meeting_status != 2) {
								return row;
							}

							//return row;
						}
					});

					break;
				case "task":
					data = this.relatedDB("task", []);
					break;
				case "projects":
					data = this.relatedDB("projects", []);
					break;
			}
			return data;
		},
		relatedProjectList() {
			const projects = this.relatedDB("projects", []);
			return filter(projects, (row) => row.customer == this.reminder.customer);
		},
		customerList() {
			return this.relatedDB("all_customers", []);
		},
		getRelatedErrorMsg() {
			let msg = "";
			switch (this.reminder.related_to) {
				case "lead":
					msg = "Company Name";
					break;
				case "customer":
					msg = "Customer";
					break;
				case "meeting":
					msg = "Meeting";
					break;
				case "task":
					msg = "Task";
					break;
				case "projects":
					msg = "Project";
					break;
			}
			return msg;
		},
	},
	mounted() {
		this.init();
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
	beforeUmMount() {
		this.$store.commit(CLEAR_REMINDER);
	},
};
</script>

<style scoped></style>
