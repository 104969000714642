import { render, staticRenderFns } from "./CreateOrUpdateReminderV2Dialog.vue?vue&type=template&id=138c716c&scoped=true"
import script from "./CreateOrUpdateReminderV2Dialog.vue?vue&type=script&lang=js"
export * from "./CreateOrUpdateReminderV2Dialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138c716c",
  null
  
)

export default component.exports